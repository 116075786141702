<template>
	<div class="main">
         <TheTopbar/>
         <slot />
	<div class="main-container">
    <div class="d-flex">
      <div class="content-wrapper">
          <div class="d-flex justify-content-between modal-header m-3"> 
                <h5 style="color: gray">TRANSACTION SEARCH</h5>
                
            </div>
        <div>
                <b-input-group prepend="Transaction ID" class="mt-3 search" >
                    <b-form-input v-model="txnId" @keyup.enter="searchByTxnId()"></b-form-input>
                    <b-input-group-append>
                    <b-button variant="outline-success"  v-on:click="searchByTxnId()">Search</b-button>
                   
                    </b-input-group-append>
                </b-input-group>
          </div>
        <div class="modal-body">
              <div class="market-content">
                <div class="row content-wrapper">
                    <div class="row option-wrapper">
                      <div class="card-date">
                        <!-- Start Date Input -->
                        <input type="date" v-model="fromDate" class="form-control form-control-date"  format="MM/dd/yyyy" id="fromDate"/>
                      </div>
                      <div class="card-date">
                      <!-- End Date Input -->
                      <input type="date" v-model="toDate" format="MM/dd/yyyy " id="toDate" class="form-control form-control-date" />
                      </div>
                      <div class="card-date"> 
                      <b-form-select class="form-control form-select" v-model="statusValue" :options="status" placeholder="Select Status"></b-form-select>
                      </div>
                      
                      <button @click="filterData" class="btn btn-dark">Filter</button>
                    </div>
                  </div>
                <div class="market-content">
                
                  <div style="color: gray" v-if="transactions.length == 0">
                      <td colspan="10">No transactions found from {{ formatDate(yesterdayDate) }} - {{ formatDate(toDate) }}</td>
                  </div>
                  <table class="table">
                    
                    <thead>
                      <tr>
                          <th>Date of Txn</th>
                          <th>Txn ID</th>
                          <th>Description</th>
                          <th>Txn Type</th>
                          <th>Txn Amount</th>
                          <th>Currency</th>
                          <th>Entity ID</th>
                          <th>IP Address</th>
                          <th>Status</th>
                      </tr>
                    </thead>
                    
                    <tbody v-for="item in transactions" :key="item.id">
                      <td>{{ item.txnDate }}</td>
                      <td>{{ item.txnId }}</td>
                      <td>{{ item.txnDesc }}</td>
                      <td>{{ item.txnType }}</td>
                      <td>{{ item.headerAmount }}</td>
                      <td>{{ item.headerCurrency}}</td>
                      <td>{{ item.entityId }}</td>
                      <td>{{ item.ipAddress }}</td>
                      <td>{{ item.txnStatus }}</td>
                    </tbody>
                    <tbody>
                      <td>{{ txnDetails.txnDate }}</td>
                      <td>{{ txnDetails.txnId }}</td>
                      <td>{{ txnDetails.txnDesc }}</td>
                      <td>{{ txnDetails.txnType }}</td>
                      <td>{{ txnDetails.headerAmount }}</td>
                      <td>{{ txnDetails.headerCurrency}}</td>
                      <td>{{ txnDetails.entityId }}</td>
                      <td>{{ txnDetails.ipAddress }}</td>
                      <td>{{ txnDetails.txnStatus }}</td>
                    </tbody>
                  </table>
                  </div>
                  </div>
              
        </div>
        
        <hr>
       
      </div>
    </div>
    </div>
   
	</div>
  
 
          
</template>
         
<script>
  import moment from 'moment';
  import TheTopbar from '../components/TheTopbar.vue'
  import Approve from '../components/modal/Approve.vue'
  import Header from '../components/Header.vue'
 

  export default {
      name: 'TransactionSearch',
      components: {
        TheTopbar,
        Approve,
        Header
         
      },
      computed: {
          show() {
              return this.$store.state.show;
          },
          init() {
              return this.$store.state.init;
          },
       },
       data: function() {
        const today = new Date();
        return { 
          txnId:'',
          showChangePin: false,
          showLogoutModal: false,
          showInputComment: false,
          fromDate: today,
          toDate: today,
          txnIdDetails:{},
          txnDetails:{},
          item:{},
          transactions: [],
          yesterdayDate:'',
          statusValue:'',
          status: [
              {text: 'Select Status', value: null}, 
              {text: 'SUCCESS', value: '11'}, 
              {text: 'PENDING', value: '80'}, 
              {text: 'CANCELLED', value: '00'}, 
              {text: 'FAILED', value: '90'}
            ],
        }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
      },
      methods:{
        getYesterdayDate() {
          const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
          return yesterday.toISOString().split('T')[0]; // Format as YYYY-MM-DD
          
        },
        goHome(){
          window.location.href="/#/customer-search";
        },
        goBack(){
          this.$router.go(-1);
        },
       
         
          formatDate: function(date) {
          return moment(new Date(date)).format('MM/DD/YYYY');
        },
          getProfile() {
            const params = {
              eid: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            data,
              url: '/ax/getCustomerProfile',
              }
              this.axios(options)
              .then((response) => {
              this.show.profile = response.data;
              this.show.corpDetails = response.data.corpDetails
               
              if(response.data.presentAddress !== undefined || response.data.presentAddress !== null){
                this.show.profile.presentAddress.numberStreet = response.data.presentAddress.numberStreet
              }
              }).catch((err) => {
                  
              })
          },
          loadTxn(){
                    const params = {
                      fromDate: moment(this.fromDate).subtract(1, 'days').format('MM/DD/yyyy'),
                      toDate: moment(this.toDate).format('MM/DD/yyyy'),
                      rowsPerPage: 50,
                      status: this.statusValue,
                      
                    }
                    const data = Object.keys(params)
                            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                            .join('&');

                      const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                          data,
                          url: '/ax/txnSearch/daterange'
                          };
                      this.axios(options)
                          .then((response) => { 
                            console.log(response.data)
                            this.transactions = response.data.rowsList
                            
                          }).catch((err) => { 
                      })
          },
          filterData(){
                    const params = {
                      fromDate: moment(this.fromDate).subtract(1, 'days').format('MM/DD/yyyy'),
                      toDate: moment(this.toDate).add(1, 'days').format('MM/DD/yyyy'),
                      rowsPerPage: 50,
                      status: this.statusValue,
                      
                    }
                    const data = Object.keys(params)
                            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                            .join('&');

                      const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                          data,
                          url: '/ax/txnSearch/daterange'
                          };
                      this.axios(options)
                          .then((response) => { 
                            console.log(response.data)
                            this.transactions = response.data.rowsList
                            
                          }).catch((err) => { 
                      })
          },
          searchByTxnId(){
            const params = {
              txnId: this.txnId
                      
                    }
                    const data = Object.keys(params)
                            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                            .join('&');

                      const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                          data,
                          url: '/ax/txnIdSearch'
                          };
                      this.axios(options)
                          .then((response) => { 
                            this.txnIdDetails = response.data
                            console.log(this.txnIdDetails)

                            this.txnIdDetails.forEach((el, i) => {
                              this.txnDetails = el

                             
                            })
                            
                          }).catch((err) => { 
                      })
          },
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
             this.axios(options)
              .then((response) => {
              
              }).catch((err) => {
                 

                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
      mounted(){
          this.sessionCheck();
          this.loadTxn();
          const yesterdayDate = this.getYesterdayDate();
          this.yesterdayDate = yesterdayDate
      }
  }
</script>
        
<style lang=scss scoped>
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }
 .market-content {
  width: 100%;
      border-radius: 10px;
      padding: 0;
      max-height: 450px; /* Set your desired maximum height */
      overflow: auto;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
  .content-wrapper{
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center !important;
    width: 100%;
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #ffffff;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
  }

  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
  .active {
    background-color: #695536;
  }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}

label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}

   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}
  
  </style>